import React from "react";
import ReactPaginate from "react-paginate";
import { Col, Row } from "react-bootstrap";
import SEO from "@components/SEO";
import Layout from "@components/Layout";

import portoStyles from "@cssmodules/portfolio.module.scss";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Portfolio(props) {
    const pathKey = props.pageContext?.key;
    console.log(props)
    const images = useStaticQuery(graphql`
        query MyQuery {
            seragam_pria: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/pria)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_wanita: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/wanita)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_lapangan: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/lapangan)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_security: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/security)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_teknisi: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/teknisi)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_hotel: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/hotel)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_restauran: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/restaurant)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_pabrik: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/pabrik)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_spa: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/spa)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_perawat: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/perawat)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_otomotif: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/otomotif)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
            seragam_komunitas: allFile(
                sort: { fields: name }
                filter: {
                    extension: { regex: "/(jpg)/" }
                    dir: { regex: "/(portofolio/komunitas)/" }
                }
            ) {
                edges {
                    node {
                        id
                        childImageSharp {
                            fluid(maxHeight: 340, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        base
                    }
                }
            }
        }
    `);

    const [activePages, setActivePages] = React.useState(0);
    const renderPorto = () => {
        const firstIndex = activePages * 16;
        
        let porto = []
        if (images[pathKey]?.edges.length > 0) {
            porto = [...images[pathKey]?.edges].slice(
                firstIndex,
                firstIndex + 16
            );
        }

        const view = porto.map(({ node }) => (
            <Col className={portoStyles.col} key={node.id} xs={6} md={6} lg={3}>
                <div className={portoStyles.card}>
                    <Img
                        className="h-100"
                        fluid={node.childImageSharp.fluid}
                        alt={node.base.split(".")[0]}
                    />
                </div>
            </Col>
        ));

        return view;
    };

    const handlePageClick = data => {
        setActivePages(data.selected);
        window.scrollTo(0, 0);
    };

    return (
        <Layout>
            <main className={`container ${portoStyles.container}`}>
                <SEO title="Portfolio" />
                <h2>
                    Berikut Beberapa Portofolio <b>{props.pageContext.title}</b>
                </h2>
                <Row>{renderPorto()}</Row>
                <ReactPaginate
                    previousLabel={null}
                    nextLabel={null}
                    breakLabel={"..."}
                    breakClassName={portoStyles.break}
                    pageCount={Math.ceil(images[pathKey]?.edges.length / 16)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={portoStyles.pagination}
                    pageClassName={portoStyles.paginationSub}
                    activeClassName={portoStyles.active}
                />
            </main>
        </Layout>
    );
}
